import React, { useState } from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"

import Information from "../Information"

import thumb1 from "../../../images/portfolio/krfty/thumbnails/1.jpg"
import thumb2 from "../../../images/portfolio/krfty/thumbnails/2.jpg"
import leftArrow from "../../../images/left-arrow.svg"
import rightArrow from "../../../images/right-arrow.svg"

const StyledKrfty = styled.div`
  height: 100vh;
  padding-top: 13vw;
  padding-left: 5vw;
  .container {
    display: grid;
    grid-template-columns: 3.5fr 9fr;
    height: 100%;
    .information {
      .all-projects-wrapper {
        margin-bottom: 5vw;
        .all-projects {
          color: #6d6e71;
          font-size: 1.1vw;
          margin: 0;
          font-weight: 400;
        }
      }
      .details-wrapper {
        padding-right: 5vw;
        .details-title {
          h1 {
            font-size: 2vw;
            font-weight: 400;
            margin-bottom: 2vw;
          }
        }
        .details {
          display: grid;
          grid-template-columns: 1fr 1fr;
          .item {
            margin-bottom: 2vw;
            font-family: "Akzidenz Grotesk Extended";
            color: #58595b;
            .item-heading {
              font-weight: 600;
            }
            .item-description {
            }
          }
        }
      }
    }
    .gallery {
      display: grid;
      grid-template-columns: 1fr 4fr;
      .thumbnails {
        padding: 0 2vw;
        .thumbnail {
          width: 100%;
          height: 100px;
          margin-bottom: 1vw;
          background-color: transparent;
          background-size: cover;
          background-position: center;
          cursor: pointer;
          transition: transform 0.1s;
          :hover {
            transform: scale(1.1);
            transition: transform 0.2s;
          }
        }
        .thumbnail-1 {
          background-image: url(${thumb1});
        }
        .thumbnail-2 {
          background-image: url(${thumb2});
        }
      }
      .image-wrapper {
        position: relative;
        .left-arrow {
          position: absolute;
          left: 20px;
          top: 50%;
          transform: translateY(-50%);
          width: 30px;
          cursor: pointer;
          img {
            margin: 0;
          }
        }
        .right-arrow {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          width: 30px;
          cursor: pointer;
          img {
            margin: 0;
          }
        }
        .pagination {
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
          display: grid;
          grid-template-columns: auto auto auto auto auto auto;
          grid-gap: 6px;
          .dot {
            background-color: transparent;
            width: 10px;
            height: 10px;
            border: 1px solid #58595b;
            border-radius: 50%;
            cursor: pointer;
            &.active {
              background-color: #58595b;
            }
          }
        }
      }
    }
  }
`

const Krfty = () => {
  const [selected, setSelected] = useState(1)
  const total = 2
  const myArray = Array(total)
    .fill(null)
    .map((_, i) => i + 1)
  const data = useStaticQuery(graphql`
    query {
      full1: file(relativePath: { eq: "portfolio/krfty/full-size/1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      full2: file(relativePath: { eq: "portfolio/krfty/full-size/2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <StyledKrfty>
      <div className="container">
        <Information
          title="KRFTY"
          role="Label Printing"
          client="Krfty"
          categories="Others"
        />
        <div className="gallery">
          <div className="thumbnails">
            <div
              className="thumbnail thumbnail-1"
              onClick={() => setSelected(1)}
            ></div>
            <div
              className="thumbnail thumbnail-2"
              onClick={() => setSelected(2)}
            ></div>
          </div>
          <div className="image-wrapper">
            <div>
              {selected === 1 && (
                <Img fluid={data.full1.childImageSharp.fluid} />
              )}
              {selected === 2 && (
                <Img fluid={data.full2.childImageSharp.fluid} />
              )}
            </div>
            <div className="pagination">
              {myArray.map(x => (
                <div
                  className={`dot ${selected === x ? "active" : ""}`}
                  onClick={() => setSelected(x)}
                ></div>
              ))}
            </div>
            {selected !== 1 && (
              <div
                className="left-arrow"
                onClick={() => setSelected(selected - 1)}
              >
                <img src={leftArrow} alt="left arrow" />
              </div>
            )}
            {selected !== total && (
              <div
                className="right-arrow"
                onClick={() => setSelected(selected + 1)}
              >
                <img src={rightArrow} alt="right arrow" />
              </div>
            )}
          </div>
        </div>
      </div>
    </StyledKrfty>
  )
}

export default Krfty
